<template>
  <label
    :for="id"
    class="inline-flex relative items-center cursor-pointer mr-2"
    :class="small ? 'h-4' : 'h-6'"
  >
    <input
      :id="id"
      type="checkbox"
      :checked="modelValue"
      :disabled="isDisabled"
      class="sr-only peer"
      @change="update($event.target.checked)"
    >
    <div
      class="overflow-hidden bg-gray-600 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
      :class="small ? 'w-8 h-4 after:h-3.5 after:w-3.5 after:top-[1px] after:left-[2px]' : 'w-11 h-6 after:h-5 after:w-5 after:top-[2px] after:left-[2px]'"
    />
    <span
      v-if="label"
      class="ml-3 text-sm tracking-wider whitespace-nowrap"
      :class="small ? 'text-xs' : 'text-base'"
    >
      {{ label }}
    </span>
  </label>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  isDisabled: Boolean,
  small: Boolean,
  modelValue: Boolean,
});

const emits = defineEmits([
  'update:modelValue',
]);

function update(value) {
  if (!props.isDisabled) {
    emits('update:modelValue', value);
  }
}
</script>
